@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

.tenants-container {
    .cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        flex-direction: row;
        justify-content: space-between;

        .card {
            border-radius: 0.625rem;
            background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);
        }

        .free-plan {
            display: inline-flex;
            height: 4.25rem;
            padding: 0.875rem 1rem 0.875rem 1.1875rem;
            justify-content: space-between;
            align-items: center;
            flex-shrink: 0;
            border-radius: 0.625rem;
            background: linear-gradient(90deg, #1e9ce8 0%, #f40e6f 100%);
            margin-bottom: 0.5em !important;

            span {
                color: #f8f8f8;
                text-align: center;
                font-family: Montserrat;
                font-size: 0.6875rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            h5 {
                color: #fff;
                text-align: center;

                /* HEADING/H5-Bold */
                font-family: Montserrat;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 0;
            }

            button {
                color: var(--SECONDARY-PINK, #f40e6f);
                text-align: center;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                border-radius: 0.625rem;
                background: var(--ACCENT-WHITE, #fff);
                border: none;
                width: 8.75rem;
                height: 2.5rem;
                flex-shrink: 0;
            }
        }

        .card-holder {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .card {
            margin: 0.25em 0.5em !important;
            padding: 1em;
            border: 1px solid #e4e8f1;
            width: inherit;
            align-self: stretch;
            width: 100% !important;

            .card-main-button {
                font-weight: 600;
                border-radius: 8px;
                font-size: 16px;
                line-height: 16px;
                padding: 15px 32px;
                text-align: center;
                display: inline-block;
                text-decoration: none;
                margin-right: 30px;
                transition: all 0.3s;
                height: auto;
                cursor: pointer;
                background: var(--parla-primary);
                color: #fff;
                box-shadow: 0 15px 15px rgba(125, 147, 178, 0.3);
                border: none;
                width: 80%;
            }

            .card-main-button:hover {
                background: #cec3fc;
                box-shadow: 0 10px 20px rgba(125, 147, 178, 0.5);
            }
        }

        .card-body {
            margin: 0;

            .card-wrapper {
                display: flex;
                color: #717171;

                .card-img-icon {
                    height: 40px;
                    margin-left: 20px;
                }
            }

            .card-wrapper-end {
                color: #717171;
                padding-top: 28px;
            }
        }

        .card-subtitle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            margin-bottom: 0.88em;
        }

        .custom-card-footer {
            display: flex;
            justify-content: space-between;

            .plan-name {
                font-size: 18px;
                font-weight: 600;
            }

            .plan-change {
                font-size: 18px;
                color: var(--parla-primary);
                padding-left: 5px;
            }

            .plan-cancel {
                color: #8f959c;
            }
        }

        .card-footer-section {
            display: flex;
            flex-direction: column;
            margin-top: 50px;
            align-items: center;

            label.card-wrapper {
                margin-top: 25px;
                margin-bottom: -10px;
            }
        }

        .icon-btn {
            padding: 2px 10px;
            color: var(--parla-primary);
            font-size: 20px;
        }

        .text-btn {
            padding: 2px 10px;
            color: #0d0f38;
            font-size: 15px;
        }

        .contact-manage-btn {
            cursor: pointer;
            color: var(--parla-primary);
            padding: 6px 0 0 0;
        }

        .contact-invoice-msg {
            color: var(--ACCENT-SHADE_OF_BLACK_3, #727272);
            text-align: center;
            font-family: Montserrat;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 1.5em;
            margin-bottom: 1em;
        }

        .contact-invoice-wrapper {
            max-height: 80px;
            text-overflow: ellipsis;
            overflow-y: auto;

            .contact-invoice-list {
                font-weight: 300;
                color: #8f959c;
                font-size: 14px;
                display: flex;
                gap: 0.625em;

                .contact-invoice-item {
                    display: flex;
                    height: 2.1875rem;
                    padding: 0.5rem 1.375rem;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: 1.25rem;
                    background: var(--ACCENT-SHADE_OF_DARK_BLUE_3, #deefff);
                }
            }
        }

        .billing-contacts-empty-image {
            svg {
                height: 80px;
                margin-bottom: 1em;
            }

            label {
                font-size: 0.8em;
            }
        }

        .plan-price {
            font-size: 30px;
            color: var(--parla-primary);
            padding-top: 25px;
        }

        .price-tax {
            font-size: 14px;
            color: #818589;
        }

        .price-next {
            color: #818589;
        }

        .price-full {
            color: var(--parla-primary);
            // padding: 30px 0;
        }

        .card-holder-row {
            margin-left: 15px;
            width: 100%;
            margin-right: -15px;
        }
    }

    .billing-summary-header-list {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 0em);
        padding: 0.6875rem 1.6875rem 0.8125rem 1.4375rem;
        border-radius: 0.625rem;
        background: var(--ACCENT-WHITE, #fff);

        span {
            color: var(--TERTIARY-DARK_BLUE, #102e4a);

            /* SMALL TEXT/BOLD */
            font-family: Montserrat;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        span:nth-child(1) {
            width: 8em;
        }

        span:nth-child(4) {
            margin-right: 3em;
        }
    }

    .custom-billing-accordion {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .accordion-card-header {
            width: 100%;
            box-shadow: none;
            padding: 0.5rem 0.75rem;

            .crm-header-analytic-list {
                list-style: none;
                display: flex;
                margin: 0;

                >li {
                    padding-left: 25px;
                    font-weight: 600;
                    color: #5e5e5f;
                }
            }

            .billing-summary-item {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                flex-basis: calc(100% / 5);
                font-size: 14px;
                width: 100%;
                color: var(--ACCENT-SHADE_OF_BLACK_4, #4c4c4c);
                text-align: center;
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                i {
                    padding-right: 5px;
                    color: #5e5e5f;
                }

                div:nth-child(1) {
                    width: 8em;
                }
            }
        }

        .billing-line-data {
            width: 100%;

            .billing-accordion-inside {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                flex-basis: calc(100% / 4);
                white-space: pre;

                .billing-accordion-label {
                    align-self: center;
                }

                .billing-accordion-inside-list {
                    list-style: none;

                    .left {
                        float: left;
                    }

                    .right {
                        float: right;
                    }
                }
            }
        }
    }
}

.company-header {
    background: #f8f8f8;
    border-radius: 10px;
    overflow: hidden;

    .company-header-bar {
        height: 50px;
        width: 100%;
        background: linear-gradient(90deg, #1e9cfe 10%, #f40e6f 100%);
    }

    .company-main {
        img {
            max-height: 75px;
            background: #fff;
            padding: 15px;
            border-radius: 10px;
            margin: -20px 15px 15px 15px;
        }
    }

    .company-name-wrapper {
        padding: 0 20px 15px;
        display: flex;
        justify-content: space-between;

        .company-name {
            color: #102e4a;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .company-header-from {
        color: #4c4c4c;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 5px;
    }
}

.active-toggle {
    border-radius: 10px;
    background: #fff;
    padding: 7px 18px;
    color: #1e9ce8;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .react-toggle {
        margin-left: 10px;
    }
}

.permissions-section {
    border-radius: 10px;
    border: 1px solid #808080;

    .active-toggle-wrap {
        display: flex;
    }

    .active-toggle {
        background: #f8f8f8;
        margin: 0 15px 15px;
        height: 44px;
        justify-content: space-between;
    }

    .permissions-title {
        font-size: 20px;
        font-weight: 600;
        padding: 15px 20px;
        color: #102e4a;
    }
}

.save-btn {
    background: #4caf50;
    border: 1px solid #3a8d3d;
    border-radius: 4px;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    height: 35px;
    margin-top: 5px;
    transition: 1s;
    width: 100%;
}

.hover-180:hover {
    transform-style: all;
    transition-duration: 0.5s;
    transform: rotate(180deg);
}

.edit-integration {
    display: flex !important;
    align-items: center;

    .modal-dialog {
        width: 80%;
        max-width: 80%;
        height: 80%;

        .modal-content {
            height: 100%;

            .list-group {
                height: 100%;

                iframe {
                    height: 100%;
                }
            }
        }
    }
}

.terms-view {
    .text-title-label {
        color: #1e9ce8;
        font-size: 18px;
        font-weight: 600;
        padding: 30px 0 15px;
    }

    .terms-and-conditions {
        width: 100%;
        height: 30vh;
        border-radius: 10px;
        background: #f8f8f8;
        border: none;
        padding: 15px;
        font-size: 14px;
    }
}

.billings-modal {
    .user-name-email {
        margin-left: 15px;

        label {
            margin-bottom: 0;
        }

        .user-name {
            padding-right: 5px;
            text-transform: capitalize;
        }

        .user-email {
            font-size: 13px;
            color: #8f959c;
            margin-left: 10px;
        }
    }
}

.balances-card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .transactions {
        .transactions-header-group {
            border-radius: 0.625rem;
            background: var(--ACCENT-WHITE, #fff);
            display: inline-flex;
            justify-content: space-between;
            padding: 0.6875rem 1.4375rem 0.8125rem 1.4375rem;
            align-items: center;
            width: 100%;
            overflow-x: hidden;

            .transaction-header {
                color: var(--TERTIARY-DARK_BLUE, #102e4a);
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                &:first-child,
                &:last-child {
                    min-width: 5em;
                }

                &:last-child {
                    text-align: right;
                }
            }
        }

        .paid-transaction,
        .unpaid-transaction {
            display: flex;
            justify-content: space-between;
            padding: 0 1.4375rem 0 1.4375rem;
        }

        .transaction-data {
            font-size: 12px;
            font-weight: 300;
            text-align: left;
            padding: 0.63em 0;
            cursor: pointer;

            &:first-child,
            &:last-child {
                min-width: 5em;
            }

            &:last-child {
                text-align: right;
            }
        }

        .paid-transaction {
            color: darkgreen;
        }

        .unpaid-transaction {
            color: darkred;
        }

        .btn.verify {
            padding: 0px;
        }
    }

    .balance {
        font-size: 30px;
        color: var(--parla-primary);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1em;
    }

    .current-balance {
        font-size: 25px;
        color: var(--parla-primary);
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1em;
        cursor: pointer;
    }

    .balance-control {
        display: flex;

        .pending-transactions {
            display: flex;
            gap: 0.5em;
        }
    }

    .balances {
        display: flex;
        flex-wrap: wrap;
        gap: 0.2em;

        .bal-min-max {
            font-size: 12px;
            display: block;
            width: 100%;
            color: #afafaf;
            font-weight: 600;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        .topup-button {
            border-radius: 0.625rem;
            background: var(--ACCENT-WHITE, #fff);
            margin-left: 0.5em;

            &:first-child {
                margin-left: 0;
            }

            &:hover:not(.input) {
                background: #ffe7f1;
            }

            &.input .btn {
                text-align: left;
                width: 10.125rem;
                display: flex;
                justify-content: space-between;
            }

            button {
                color: var(--SECONDARY-PINK, #f40e6f);
                text-align: center;
                font-family: Montserrat;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                background: transparent;
                padding: 0.75em;

                &:hover {
                    color: var(--SECONDARY-PINK, #f40e6f);
                    background: transparent;
                }
            }
        }

        .custom-topup {
            border: none;
            background: none;
            font-weight: bold;
            padding: 0;
            flex-shrink: 0;
            margin-left: 0.25em;
            width: 5em;
            color: var(--SECONDARY-PINK, #f40e6f);

            &:focus {
                border: none;
                outline: none;
            }

            &::placeholder {
                color: var(--ACCENT-SHADE_OF_BLACK_5, #949494);
                font-family: Montserrat;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .verify:focus {
            box-shadow: none;
        }

        .continue {
            text-decoration: none;
            letter-spacing: -0.015em;
            font-size: 14px;
            color: #fff;
            background: black;
            font-weight: bold;
            border-radius: 50px;
            text-align: right;
            @include transition(all, 0.25s, linear);
            margin: 0px 10px 10px 0px;

            &:after {
                content: "\276f";
                padding: 0.5em;
                position: relative;
                right: 0;
                @include transition(all, 0.15s, linear);
            }

            &:hover,
            &:focus,
            &:active {
                background: var(--parla-primary);
                color: #fff;

                &:after {
                    right: -10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 780px) {
    .plan-counter {
        justify-items: center;
    }
}
$public: "../../public";

.tenants-page {
    display: flex;
    width: 90vw;
    margin: 25px 5vw;
    background: #fff;
    padding: 35px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .search-tenants {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 25px;

        .search {
            align-items: center;
            background-color: #f4f4f4;
            border: none;
            border-radius: 10px;
            display: flex;
            width: 100%;
            position: relative;

            .fa-search {
                position: absolute;
                padding-left: 15px;
            }
        }

        .tenants-search {
            width: 100%;
        }

        button {
            background: none;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            height: auto;
            line-height: 16px;
            padding: 15px 32px;
            text-align: center;
            text-decoration: none;
            transition: all 0.3s;

            &:hover {
                background: var(--parla-secondary);
                box-shadow: 0 10px 20px #7d93b280;
                color: #fff;
            }
        }

        .searchTerm {
            border: none;
            width: 100%;
            padding: 15px 45px;
            border-radius: 8px;
            background-color: transparent;
            color: #4c6280;
            height: 48px;
            margin-right: -75px;
        }
    }

    .tenants-container {
        display: flex;
        flex-wrap: wrap;

        .asset-parent-wrapper {
            position: relative;
        }

        .company-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px;

            .tenant-badge {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 146px;
                height: 132px;
                flex-shrink: 0;
                border-radius: 10px;
                border: 1px solid #808080;
                background: #fff;
                margin-bottom: 10px;

                label {
                    color: #2e2e2e;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                i {
                    font-size: 30px;
                    color: #102e4a;
                }

                .fa-plus {
                    font-size: 20px;
                }

                .no-asset {
                    display: flex;
                    width: 135px;
                    height: 100px;
                    background-image: url("#{$public}/parla-logo-white.png");
                    background-repeat: no-repeat;
                    background-size: 60%;
                    background-position: center;
                    filter: grayscale(100%) opacity(0.2);
                }

                img {
                    object-fit: contain;
                    position: absolute;
                    left: 0;
                }

                .failed-asset {
                    img {
                        object-fit: contain;
                        position: absolute;
                        left: 0;
                    }
                }

                &.add-company {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    label {
                        font-size: 14px;
                    }
                }
            }
        }

        .tenant-badge:hover {
            border: 2px solid #0d0f38;
        }
    }

    .pagination {
        display: flex;
        flex-direction: row;
        height: 10px;
        justify-content: center;
        list-style-type: none;
        position: relative;
        padding-top: 15px;
    }
}

.tenant-shop-wrapper {
    margin: 25px 0;
    border-radius: 10px;
    border: 1px solid #808080;

    .shop-wrapper-title {
        font-size: 20px;
        font-weight: 600;
        padding: 15px 20px;
        color: #102e4a;
    }

    .shop-wrapper {
        display: flex;
        padding: 0 20px 25px;
        justify-content: space-between;

        label {
            color: #4c4c4c;
            text-transform: uppercase;
            font-size: 14px;
        }

        .asset-selector {
            margin: 0;

            .section-card-group {
                margin: 0;

                .card-group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    height: 315px;
                    cursor: pointer;
                    overflow: hidden;
                    box-shadow: none;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 43.34%, #000 85.27%);
                    border: none;

                    img {
                        object-fit: cover;
                    }
                }
            }
        }

        .logo-card,
        .intro-card {
            .asset-selector {
                .section-card-group {
                    .card-group {
                        img {
                            object-fit: contain;
                            padding: 25px;
                        }

                        .card-quote {
                            font-size: 14px;
                            width: 100%;
                            padding: 25px;
                            color: #1b3752;
                        }
                    }
                }
            }
        }
    }

    .form-group {
        display: block;
        min-width: 32%;
        flex-grow: 1;
        margin: 0 10px 10px 0;

        .col-form-label {
            padding-top: calc(0.375rem + 1px);
            padding-bottom: calc(0.375rem + 1px);
            margin-bottom: 0;
            font-size: inherit;
            line-height: 1.5;
            display: contents;
        }
    }

    .subdomain-card {
        padding: 0px 20px 10px;

        input {
            border: 1px solid #808080;
            border-radius: 8px;
            font-size: 14px;
        }

        label {
            color: #4c4c4c;
            text-transform: uppercase;
            font-size: 14px;
            padding-top: 15px;
            padding-bottom: 5px;
        }
    }
}

.company-section {
    label {
        color: #4c4c4c;
        text-transform: uppercase;
        font-size: 14px;
        padding-top: 15px;
        padding-bottom: 5px;
    }

    input {
        border: 1px solid #808080;
        border-radius: 8px;
        font-size: 14px;
    }

    .rmsc {
        .dropdown-container {
            border: 1px solid #808080;
            border-radius: 8px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 720px) {
    .tenant-shop-wrapper {
        .shop-wrapper {
            flex-direction: column;

            .asset-selector {
                .section-card-group {
                    min-width: fit-content;

                    .card-group {
                        width: 100%;
                        max-width: 230px;
                    }
                }
            }
        }
    }

    .permissions-section {
        .active-toggle-wrap {
            flex-direction: column;
        }
    }

    .company-name-wrapper {
        flex-direction: column;

        .company-header-from {
            padding: 10px 0;
        }
    }

    .background-card {
        padding: 15px 0;
    }
}

.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f0f0;
}

.loader-container {
    position: relative;
    width: 100px;
    height: 100px;
}

.loader {
    transform: rotate(-90deg);
}

.loader-bg {
    fill: none;
    stroke: #e0e0e0;
    stroke-width: 10;
}

.loader-fg {
    fill: none;
    stroke: #1e9ce8;
    stroke-width: 10;
    stroke-dasharray: 283;
    transition: stroke-dashoffset 0.1s ease;
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 1.5em;
}
.prices-component {
    .price-input-group {
        display: flex;
        flex-direction: row;
    }
    .prices-list {
        list-style: none;

        li {
            width: 150px;
            display: flex;
            justify-content: space-between;
        }
    }
}

.remove-price {
    color: #fff;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

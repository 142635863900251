.input-tag {
    background: white;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 5px 0;
}

.input-tag .input-tag__tags .input-tag__tags__input input {
    width: 100%;
    border: none;
}

.input-tag__tags {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
}

.input-tag__tags li {
    align-items: center;
    background: #dddddd;
    border-radius: 2px;
    color: #256483;
    display: flex;
    font-weight: 600;
    list-style: none;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 50%;
    color: #212529;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    width: 15px;
}

.input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
}

.error-message {
    color: red;
}
$breakpoint-alpha: 480px;

.calls-table {
  margin: 1em 0;
  min-width: 300px;
  width: 100%;

  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  th {
    display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!   
  }

  td {
    display: block;

    &:first-child {
      padding-top: .5em;
    }

    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": ";
      font-weight: bold;
      width: 6.5em; // magic number :( adjust according to your own content
      display: inline-block;
      // end options

      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;

    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.calls-table {
  // background: #34495E;
  color: #fff;
  border-radius: .4em;
  overflow: hidden;

  tr {
    border-color: lighten(#34495E, 10%);
  }

  th,
  td {
    margin: .5em 1em;

    @media (min-width: $breakpoint-alpha) {
      padding: 1em !important;
    }
  }

  th,
  td:before {
    color: #dd5;
  }

  .calls-table-row {
    cursor: pointer;
  }
}
.twilio-notes-component {
    bottom: 110px;
    left: 380px;
    position: fixed;

    #margin {
        margin-left: 12px;
        margin-bottom: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    #text {
        width: 100%;
        overflow: hidden;
        background-color: #fff;
        color: #222;
        font-weight: normal;
        font-size: 15px;
        resize: none;
        line-height: 40px;
        padding: 21px;
        -webkit-border-radius: 12px;
        border-radius: 12px;
        box-shadow: 0px 2px 4px #000;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }

    #wrapper {
        width: 200px;
        height: auto;
    }

    .one-to-one-notes {
        display: flex;
        flex-direction: column;
    }
}

.one-to-one-analytics {
    background: white;
    border-radius: 10px;
    position: absolute;
    left: 175px;
    bottom: 158px;
    height: 175px;
    width: 300px;
    -webkit-box-shadow: 0px 0px 5px 0px #0006;
    -moz-box-shadow: 0px 0px 5px 0px #0006;
    box-shadow: 0px 0px 5px 0px #0006;
    font-weight: 600;

    .title {
        font-weight: 600;
        padding: 10px 10px;
    }

    .live {
        background: red;
        color: #fff;
        font-size: 10px;
        float: right;
        padding: 5px;
    }

    .analytic-header {
        list-style: none;

        .open-product {
            cursor: pointer;
            text-align: right;
            white-space: nowrap;
            width: calc(100% - 110px);
            overflow: hidden;
            text-overflow: ellipsis;
            span {
                width: 100%;
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                padding-left: 5px;
            }
        }
        li {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            line-height: 30px;

            .text {
                i {
                    padding-right: 10px;
                }
            }

            .number {
                font-weight: 600;
            }
        }
    }

    .open-product {
        display: flex;
        cursor: pointer;
    }

    .mini-open-product {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.inline-editor {
    display: flex;
    padding: 1em;

    .text-editor {
        margin-top: -3px;
        padding-left: 1em;
        text-decoration: underline;
        text-decoration-line: dashed;
        text-decoration-color: blue;
    }

    .inline-label {
        display: block;
    }
}
.tooltip {
    .tooltip-inner {
        .menu-option {
            padding: 4px;

            .copy-journey-table-button {
                padding-left: 2px;
                cursor: pointer;
                color: #ccc;
            }

            .veiw-journey-table-button {
                padding-left: 2px;
                cursor: pointer;
            }
        }
    }
}

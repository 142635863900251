.item-fadeout {
  -webkit-animation: fadeout 2.5s linear forwards;
  animation: fadeout 2.5s linear forwards;
}

.item-fadein {
  -webkit-animation: fadein 2.5s linear forwards;
  animation: fadein 2.5s linear forwards;
}

.item-fadeout-quick {
  -webkit-animation: fadeout 0.5s linear forwards;
  animation: fadeout 0.5s linear forwards;
}

.item-fadein-quick {
  -webkit-animation: fadein 0.5s linear forwards;
  animation: fadein 0.5s linear forwards;
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.upload-progress-wrapper {
  width: 220px;
  min-height: 260px;
  background: #00968857;
  border: 4px solid #009688;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  position: absolute;
  bottom: 40px;
  left: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.progress-bar-background {
  width: 100%;
  background-color: #ddd;
}

.progress-bar {
  height: 6px;
  background-color: #009688;
  line-height: 30px;
  color: white;
}

.complete-progress-spacer {
  width: 100%;
  line-height: 30px;
  height: 6px;
}

.file-upload {
  display: flex;
  justify-content: center;
  padding: 3px;
  flex-direction: column;
  width: 100%;
}

.file-upload-success {
  display: flex;
  justify-content: center;
  padding: 3px;
  flex-direction: column;
  width: 100%;
  color: green;
}

.name {
  width: 180px;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  text-overflow: ellipsis;
  /* word-break: break-all; */
  white-space: nowrap;
}

.upload-file-name {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.upload-img {
  width: 70%;
}
.nested-integrations {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 1rem;
    justify-content: space-around;
    padding-top: 15px;
}

iframe {
    border: none;
    height: 315px;
    overflow: auto;

    #root {
        margin: 0;
    }
}

.integrations-page {
    .dashboard-header {
        padding: 0;
    }
    .text-title-label {
        color: #1e9ce8;
        font-size: 18px;
        font-weight: 600;
        margin: 30px 0 15px;
    }
    .integrations-wrapper-new,
    .integrations-wrapper-add {
        display: flex;
        flex-wrap: wrap;
        flex-flow: row wrap;
        justify-content: start;

        .integrations-wrapper-new::after,
        .integrations-wrapper-add::after {
            content: "";
            flex: auto;
        }
    }
    .integration {
        height: 220px;
        margin: 0 15px 15px 0;
        border-radius: 8px;
        position: relative;
        max-width: 320px;
        padding: 15px;
        width: 300px;
        background: #f8f8f8;

        .title {
            display: flex;
            .title-wrapper {
                margin-left: 10px;
                .integration-title {
                    width: calc(100% - 90px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: #102e4a;
                    font-weight: bold;
                }
                .integration-subtitle {
                    color: #4c4c4c;
                    font-size: 12px;
                }
            }
            .asset-parent-wrapper {
                img {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background: #fff;
                    object-fit: contain;
                    display: block;
                    padding: 5px;
                }
            }
        }

        .description {
            color: #0f0f0f;
            padding-top: 15px;
            display: -webkit-box;
            text-align: justify;
            font-size: 12px;
            line-height: 18px;
        }
        .integration-buttons .btn {
            margin: 4px 4px 0px 0px;
        }
    }
    .installed-integrations {
        .integration-buttons {
            display: flex;
            height: 40px;
            position: absolute;
            width: calc(100% - 25px);
            bottom: 10px;
        }
        .btn {
            width: 50%;
            font-size: 14px;
            font-weight: 600;
            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                margin-left: 10px;
            }
        }
    }
    .new-integrations {
        .integration-buttons {
            display: flex;
            height: 40px;
            flex-direction: row-reverse;
            position: absolute;
            width: calc(100% - 25px);
            top: 5px;
        }
    }
}

.integration-set {
    .btn {
        margin-right: 10px;
    }
}

.video-toggle {
    margin-bottom: 15px;
}

.remove-integration.btn.btn-danger {
    width: 100%;
}

.nested-integration {
    position: relative;
    width: 300px;
    display: flex;
    height: 444;
    flex-direction: column;
    box-shadow: 0px 0px 10px 0px rgb(204, 204, 204);
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
}

.integrations-number {
    margin-top: 10px;

    .integrations-number-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;
    }
}
.integration-header-card {
    .integration-header-wrap {
        display: flex;
        .integration-logo {
            margin-right: 10px;
            img {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background: #f8f8f8;
                padding: 5px;
            }
        }
        .title {
            .logo-name-placeholder {
                width: calc(100% - 90px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #102e4a;
                font-weight: bold;
            }
            .subtitle {
                color: #4c4c4c;
                font-size: 12px;
            }
        }
    }
}

.video-toggle-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

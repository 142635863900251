.close-modal {
  font-size: 22px;
  cursor: pointer;
  padding: 0 10px;
}

.modal-param-active {
  height: 25px;
  width: 25px;
  background-color: #359222;
  border-radius: 50%;
  display: inline-block;
}

.subdomain-wrap {
  color: rgb(150, 150, 150);
  font-weight: 400;

  .form-control {
    width: auto;
    display: inline;
  }

}

.importer-modal {
  width: 1000px !important;

  button {
    padding-left: 0.4em;
  }

  .CSVImporter_TextButton {
    background: var(--parla-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    border: none;
    margin-right: 4px;
  }

  .CSVImporter_TextButton {
    &:hover {
      background: var(--parla-secondary);
    }
  }

  .CSVImporter_TextButton:hover:not(:disabled) {
    background: var(--parla-secondary);
  }

  .CSVImporter-wrapper {
    display: flex;
    margin: 10px 0;

    .CSVImporter_TextButton {
      margin-right: 4px;
    }
  }
}

.modal-param-deactivated {
  height: 25px;
  width: 25px;
  background-color: #bb2121;
  border-radius: 50%;
  display: inline-block;
}

.add-asset-content-box {
  background-clip: border-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 10px;

  .add-asset-content-box-title {
    width: 80px;
    text-emphasis: bold;
    position: absolute;
    top: 128px;
    background-color: white;
    font-weight: 600;
    padding: 0 5px;
  }
}

.select-asset {
  border: 1px solid #dee2e6;
  border-top: none;

  .filters {
    flex-direction: column;
    align-items: end;

    .search-wrapper {
      font-size: 12px;
      color: #9f9f9f;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 15px;
      width: 100%;
      flex-direction: row;

      .form-group {
        max-width: 100%;
      }

      input {
        max-width: 100%;
      }

      .multi-select {
        max-width: 100%;
      }
    }
  }

  .asset-modal-body {
    .asset-list {
      border: none;

      .asset-list-card {
        margin-right: 10px;

        .asset-tile {
          .asset-parent-wrapper {
            img {
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.create-asset-source {
  border: 1px solid #dee2e6;
  border-top: none;
  padding: 15px;
}

.file-previews {
  display: flex;
  width: 100%;
  flex-flow: wrap;
}

.modal-extra-large {
  min-width: 1000px !important;

  .code-iframe {
    background: #f1f1f1;
    padding: 15px;
    display: block;
    margin: 0 -15px;
  }

  .embed-iframe {
    padding: 2px;
    margin: 0 0 25px 0;
    width: 100%;
    box-shadow: 0px 0px 10px 0px #cccccc;
  }
}

.user-card {
  display: flex;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 0.625rem;
  background: var(--ACCENT-WHITE, #FFF);
  align-items: center;

  .user-name-email {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    label {
      margin-bottom: 0;
    }

    .user-name {
      padding-right: 5px;
      text-transform: capitalize;
    }

    .user-email {
      font-size: 13px;
      color: #8f959c;
      // margin-left: 10px;
    }
  }

  .user-default-image {
    width: 2.125rem;
    height: 2.125rem;
    flex-shrink: 0;
    background-color: #EFF2F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    vertical-align: middle;
    border-style: none;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ffffff;
    box-shadow: 0px 0px 5px 0px #cccccc;
  }
}

.radio-input {
  padding: 0.25em;
}
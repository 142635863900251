.help-panel {
    width: 35%;
    min-width: 500px;
    height: 100vh;
    overflow-y: auto;
    transition: all .6s ease-in-out;
    border: #d4d2d2;
    border-width: 1px;
    transform: translateX(100%);
    transition: .3s ease-out ease-in;
    color: #0d0f38;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 10px 10px 10px 1px rgba(0, 0, 0, 0.08);
    z-index: 2;

    &.open {
        transform: translateX(0);
    }

    .help-panel-content {
        padding: 10px;
        background-color: white;

        .help-panel-header{
            font-weight: 600;
            color: #0d0f38;

            i {
                font-size: 16px;
                line-height: 40px;
                vertical-align: middle;
            }
            .help-section-name {
                font-size: 28px;
            }
        }
        .help-bottom-panel {
            display: flex;
            justify-content: center;
            padding: 20px;
        }
        .help-panel-links {
            li {
                a {
                    text-decoration: underline!important;
                    font-weight: 600;
                    cursor: pointer;
                    line-height: 30px;
                }
            }
        }
    }
    .help-panel-video{
        width: 100%;
    }
}

.help-icon {
    height: 30px;
    font-size: 25px;
    cursor: pointer;
    position: relative;
    right: 15px;
}

.help-panel-close-icon {
    float: right;
    padding: 5px;
    cursor: pointer;
    font-size: 20px;
}
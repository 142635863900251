.sortable-container {
    width: 100%;
    position: relative;
}

.droppable-wrapper {
    display: flex;

    border: 1px solid gray;
}

.select-list {
    label {
        margin-bottom: 0;
        border-bottom: 1px solid #eee;
    }

    .card-group img {
        margin-right: 10px;
    }

    .section-title {
        font-weight: 600;
    }

    .section-subtitle {
        font-size: 13px;
    }

    video {
        width: 50px;
        height: 50px;
    }
}

/* SECTION CARD GROUP */
.section-card-group {
    margin: 15px 0;
    min-width: 300px;
    display: flex;
    max-width: calc(100% - 15px);

    li {
        list-style: none;
    }

    .draggable-item {
        border: 1px solid #eee;
        margin: 5px;
        background-color: white;
        width: 235px;
        height: 350px;
        overflow: hidden;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);

        .btn-danger {
            position: absolute;
            right: 2px;
            margin-top: 308px;
        }
    }

    .section-edit {
        position: absolute;
        background: #ffffff8c;
        padding: 12px 16px;
        font-weight: 600;
        color: #282828;
        cursor: pointer;
        margin: 5px;
        pointer-events: none;
    }

    .card-group {
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
        position: relative;

        .asset-parent-wrapper {
            max-width: 350px;

            img {
                min-height: 350px;
                max-width: 100%;
                min-width: 250px;
                object-fit: cover;
            }
        }

        .section-head {
            position: absolute;
            background: #ffffff8f;
            margin-top: 125px;
            height: fit-content;
            min-height: 50px;
            max-height: 100px;
            margin-left: 8px;
            width: 200px;
            overflow: hidden;
            padding: 5px 10px;
        }

        .section-title {
            font-weight: 600;
        }

        .section-subtitle {
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .card-quote {
            padding: 15px;
            padding-right: 45px;
            width: 250px;
        }

        video {
            min-height: 350px;
            max-width: 100%;
            min-width: 250px;
            object-fit: cover;
        }
    }
}

.asset-sources {
    .section-card-group {
        .card-group {
            .asset-parent-wrapper {
                img {
                    width: 300px;
                    object-fit: content;
                }
            }
        }
    }

    .delete-modal {
        .images {
            display: flex;
            flex-wrap: wrap;

            .asset {
                object-fit: contain;
            }
        }
    }
}

/* PRODUCT CARD GROUP */
.product-card-group {
    li {
        list-style: none;
    }

    .draggable-item {
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 5px;
        padding: 10px;
        width: 160px;
        background: #fff;
        overflow: hidden;

        &.active {
            border: solid;
            border-width: 3px;
            border-color: darkgoldenrod;
        }

        .product-name {
            text-overflow: ellipsis;
            width: 138px;
            overflow: hidden;

            a {
                font-weight: 600;
                font-size: 12px;
            }
        }

        img,
        video {
            max-width: 138px;
            height: 100%;
            max-height: 117px;
            display: block;
            margin: 0 auto;
        }

        .btn-danger {
            width: 100%;
        }
    }
}

/* CODE GROUP */
.access-code-group {
    margin-top: 25px;
    button {
        margin-right: 5px;
    }
}

.codes-group {
    flex-grow: 1;
    flex-basis: calc(100% / 5);
    font-size: 14px;
    align-items: center;
    justify-content: space-between;
    margin-left: 50px;
    margin-bottom: 10px;

    .code-name {
        font-weight: 600;
    }
}

/* SOLO SECTION */
.solo-section {
    .section-card-group {
        margin: 10px 0;

        .card-group {
            border: 1px solid #eee;
            margin: 15px 5px;
            background-color: white;
            width: 235px;
            height: 350px;
            overflow: hidden;
        }

        .btn-danger {
            position: absolute;
            margin-top: 308px;
            margin-left: 193px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                padding: 0;
            }
        }

        .card-group video {
            min-height: 150px;
            max-width: 500px;
            min-width: unset;
            object-fit: unset;
            padding-right: 50px;
        }
    }
}

/* NESTED PRODUCTS */
.nested-products {
    li {
        list-style: none;
    }

    .draggable-item {
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 5px;
        padding: 10px;
        width: 160px;
        background: #fff;

        .product-name {
            text-overflow: ellipsis;
            width: 138px;
            overflow: hidden;

            a {
                font-weight: 600;
            }
        }

        .product-image {
            max-width: 138px;
            max-height: 250px;
        }
        .product-no-image {
            filter: grayscale(100%);
            min-height: 80px;
            object-fit: contain;
        }
    }
}

.remove-button {
    position: absolute;
    bottom: 5px;
    right: 5px;

    .sortable-asset-remove-button,
    .sortable-product-remove-button {
        border-radius: 50%;
        background-color: #dc3545;
        align-self: flex-end;
        border: 2px solid #fff;
        color: #fff;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.slideshow {
    width: 100%;
    max-width: 650px;
    display: flex;
    left: 50%;
    overflow: hidden;
}

/* PRODUCT VIEW */
.product-view,
.section-view,
.section-template-view {
    li {
        list-style: none;
    }

    .draggable-item {
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 5px;
        min-width: 160px;
        background: #fff;
        overflow: hidden;

        &.active {
            border: solid;
            border-width: 3px;
            border-color: darkgoldenrod;
        }

        .product-name {
            text-overflow: ellipsis;
            width: 138px;
            overflow: hidden;

            a {
                font-weight: 600;
            }
        }

        .section-video {
            position: relative;

            video {
                vertical-align: middle;
            }
        }

        .quote-text {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                margin-bottom: 0;
                font-style: italic;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                .source-quote {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 10px;
                }
            }
        }

        .sortable-container {
            flex-flow: row-reverse;
            align-items: center;
            width: 100%;
            position: relative;
            .text-nowrap {
                position: relative;
            }

            .sortable-product-details {
                background: white;
                font-size: 12px;
                padding-top: 20px;

                .product-variant-details {
                    list-style: none;
                    padding-inline-start: 0;
                }
            }

            .asset-parent-wrapper {
                text-align: center;
            }
        }
    }
}

/*  TENANT VIEW */

.section-template-view {
    .solo-section {
        .section-card-group {
            .btn-danger {
                bottom: 0px;
                right: 0px;
                margin-top: 0px;
                margin-left: 0px;
            }
        }
    }
    .section-card-group {
        margin: 0;

        .card-group {
            margin: 15px 5px;
        }

        .btn-danger {
            z-index: 0;
        }
    }

    .logo-card {
        .card-group {
            border-radius: 4px;
        }

        .card-group img,
        .card-group video {
            min-height: 150px;
            max-width: 500px;
            padding: 15px;
            min-width: unset;
            object-fit: unset;
            padding-right: 50px;
        }
    }
}

.dropdown-customer {
    display: flex;
}

.dropdown-customer-email {
    font-size: 10px;
    margin: 5px;
}

.dropdown-customer-phone {
    font-size: 10px;
}

.dropdown-customer-name {
    margin: 5px;
}

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-modal-wrapper {
    position: fixed;
    display: flex;
    background: #77777777;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.customer-modal {
    background: white;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.new-customer-form {
    margin-bottom: 15px;
    .PhoneInput {
        input {
            height: 38px;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
        }
    }
}

.product-inventory {
    .add-inventory-button {
        color: green;
        background-color: transparent;
        border: none;
    }

    .edit-inventory-icon {
        cursor: pointer;
        color: green;
    }

    .inventory-count {
        float: right;
        color: green;
        line-height: 2.5;
    }
}

.inventory-modal {
    .inventory-button-footer-group {
        display: flex;

        .inventory-btn-save {
            font-weight: 600;
            font-family: Montserrat;
            border-radius: 8px;
            font-size: 16px;
            line-height: 16px;
            padding: 15px 32px;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            margin-right: 30px;
            transition: all .3s;
            height: auto;
            cursor: pointer;
            background: #9bc4fc;
            color: #fff;
            box-shadow: 0 20px 30px rgba(125, 147, 178, 0.3);
            border: none;
        }

        .inventory-btn-delete {
            font-weight: 600;
            font-family: Montserrat;
            border-radius: 8px;
            font-size: 16px;
            line-height: 16px;
            padding: 15px 32px;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            margin-right: 30px;
            transition: all .3s;
            height: auto;
            cursor: pointer;
            background: #ff1616;
            color: #fff;
            box-shadow: 0 20px 30px rgba(125, 147, 178, 0.3);
            border: none;
        }

        .inventory-btn-close {
            font-weight: 600;
            font-family: Montserrat;
            border-radius: 8px;
            font-size: 16px;
            line-height: 16px;
            padding: 15px 32px;
            text-align: center;
            display: inline-block;
            text-decoration: none;
            margin-right: 30px;
            transition: all .3s;
            height: auto;
            cursor: pointer;
            background: #969696;
            color: #fff;
            box-shadow: 0 20px 30px rgba(125, 147, 178, 0.3);
            border: none;
        }
    }
}

.product-inventory-shop-inputs {
    border: 1px solid #cecece;
    padding: 15px;
    border-radius: 8px;
    min-height: 150px;
    justify-content: space-evenly;
    flex-direction: column;
    display: flex;
    margin: 5px 0;

    label{
        padding-right: 10px;
    }
    .shop-input {
        display: flex;
        align-items: center;
    }
}
.table-toolbar-wrapper {
    width: 100%;
    margin-bottom: 25px;
    font-size: 14px;

    .table-toolbar-controls {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .table-toolbar-controls-wrap {
            display: flex;

            .btn-white {
                margin-right: 10px;
            }

            .btn-tertiary:first-child {
                margin-right: 10px;
            }
        }

        .input-group-prepend {
            border: none;
            background: none;
            border-radius: 5px 0 0 5px;
            margin-top: -1px;

            .input-group-text {
                font-weight: 600;
                border-radius: 8px;
                font-size: 16px;
                line-height: 16px;
                padding: 15px 32px;
                text-align: center;
                display: inline-block;
                text-decoration: none;
                transition: all 0.3s;
                background: none;
                height: auto;
                cursor: pointer;
                border: none;
            }

            .input-group-text:hover {
                background: var(--parla-secondary);
                box-shadow: 0 10px 20px rgba(125, 147, 178, 0.5);
            }
        }

        .input-group-text:hover {
            background: var(--parla-secondary);
            color: #fff;
            box-shadow: 0 10px 20px rgba(125, 147, 178, 0.5);
        }

        .search-controls {
            width: 100%;
            display: flex;
            background-color: #f4f4f4;
            border: none;
            border-radius: 10px;
            max-width: 482px;
            align-items: center;

            .far {
                padding-left: 15px;
            }

            &:focus-within {
                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
            }

            .searchTerm {
                border: none;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        .searchTerm {
            width: 100%;
            padding: 15px;
            color: #4c6280;
            height: 48px;
            background: transparent;
        }

        .clearButton {
            cursor: pointer;
            width: 40px;
            height: 38px;
            text-align: center;
            color: #fff;
            border-radius: 0 5px 5px 0;
            font-size: 20px;
        }

        .custom-select {
            width: 15%;
        }

        .filter-button {
            margin-right: 15px;
        }
    }

    .lookup-filter {
        width: 250px;
    }

    .key-value-filter {
        width: 350px;

        .key-value-data {
            .multi-select {
                width: 100%;
            }

            display: flex;
            align-items: center;

            .key-value-value {
                width: 100%;
                height: 44px;
                margin: 2px;
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0px 10px;
            }
        }
    }

    .filter-controls {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;

        .date-filter {
            .date-filter-wrap {
                flex-wrap: wrap;
            }
        }
    }

    .filter-title {
        font-weight: 600;
        font-size: 15px;
    }

    .date-filter-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .date-filter-from {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        border: 1px solid #d2d6db;
        height: 40px;
        border-radius: 10px;
        padding-right: 5px;
    }

    .date-filter-to {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        border: 1px solid #d2d6db;
        height: 40px;
        border-radius: 10px;
        padding-right: 5px;
    }

    .filter-controls div {
        margin: 2px;
    }

    .filter-controls {
        margin: 25px 0px 0px 0px;
        border: 1px solid #ededed;
        border-radius: 8px;
        padding: 15px;
        border: 1px solid #eff2f5;
        color: #102e4a;
    }

    .text-filter {
        width: 250px;

        input {
            margin: 2px;
            height: 44px;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 0px 10px;
        }
    }

    .match-filter {
        .filter-options {
            display: flex;
        }

        .radio-group {
            padding-top: 6px;
            cursor: pointer;

            .radio-input {
                display: none;
            }

            .radio {
                span {
                    font-size: 14px;
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 6px;
                }

                input[type="radio"]:checked+span {
                    background: var(--parla-primary);
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }

    .toggle-card-view {
        display: flex;
        justify-content: center;

        .toggle-card-view-bg {
            display: flex;
            align-items: center;
            padding: 8px;
            border-radius: 10px;
            background: var(--parla-primary);

            .react-toggle-track {
                background-color: unset;
                color: #fff;

                .react-toggle-track-check {
                    bottom: 6px;
                    left: 4px;
                }

                .react-toggle-track-x {
                    bottom: 6px;
                    line-height: 16px;
                    right: 10px;
                }
            }
        }
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: unset;
    }

    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: unset;
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: unset;
    }

    .react-toggle--checked .react-toggle-thumb {
        border-color: unset;
    }

    .react-toggle-thumb,
    .react-toggle--checked .react-toggle-thumb {
        border-color: unset;
        border: none;
    }
}

@media only screen and (max-width: 990px) {
    .search-controls {
        .table-toolbar-controls {
            height: auto;

            .input-group-prepend {
                position: absolute;
                right: 0;
                margin-top: 0;
            }

            .searchTerm {
                width: calc(100% - 8px);
                height: 48px;
                margin: 0 4px;
            }

            .input-group-text {
                box-shadow: none;
            }
        }
    }

    .table-toolbar-wrapper {
        .table-toolbar-controls {
            flex-direction: column;

            .create-options {
                .input-group-prepend {
                    .input-group-text {
                        margin-right: 0;
                        padding: 15px 22px;
                        box-shadow: none;
                    }
                }
            }

            .toggle-card-view {
                display: none;
            }

            .filter-button {
                margin: 10px 5px;
                height: 35px;
            }
        }
    }
}
.livestream-notes-component {
    #margin {
        margin-left: 12px;
        margin-bottom: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }

    #text {
        width: 100%;
        background-color: #fff;
        color: #222;
        font-weight: normal;
        font-size: 16px;
        resize: none;
        line-height: 28px;
        padding: 15px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    #wrapper {
        // width:700px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.alert-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    font-weight: 600;

    .close-alert-btn {
        background: transparent;
        float: right;
        border: transparent;
    }
}

.alert-component-wrapper {
    position: absolute;
    width: 100%;
    top: 62px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    padding: 1em 0em;

    .planLimitsAlert {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        font-weight: 600;
        padding-top: 0.5em;
        padding-bottom: 0.5em;

        .btn-close {
            max-height: 45px;
        }
    }
}

@media screen and (max-width: 990px) {
    .alert-wrapper {
        font-weight: 600;
    }

    .alert-component-wrapper {
        top: 40px;

        .planLimitsAlert {
            height: unset;
            min-height: 45px;
        }
    }
}
.color-0 {
    background-color: var(--parla-primary);
}

.color-1 {
    background-color: var(--parla-secondary);
}

.color-2 {
    background-color: var(--parla-tertiary);
}

.color-3 {
    background-color: #818080;
}

.availability {
    .expand {
        border: none;
        background: none;
        width: 25px;
        height: 25px;
    }
}

.chat-availability {
    align-items: center;
    display: flex;
}

.calls-availability {
    align-items: center;
    display: flex;
}

.departments-availability {
    width: 150px;
    margin-left: 20px;

    .department-availability-wrap {
        overflow: auto;
        height: 223px;

        .department-availability {
            flex-wrap: nowrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 3px;

            label {
                font-size: 8px;
            }
        }
    }
}

.dashboard-journey-select-list {
    cursor: pointer;
    color: #9bc4fc;
    padding: 0.2em;

    &.selected {
        color: white;
        background-color: #9bc4fc;
    }

    &:hover,
    &:focus,
    &:active {
        background: #9bc4fc;
        color: #fff;
        opacity: 70%;
    }
}

.tenants-container {
    .card {
        .text-inverse {
            color: #b2babf;
        }
    }

    .cards-wrapper {
        .card {
            width: calc(100% - 30px);
            margin: 15px;
        }
    }
}

.dashboard-pending-orders-card {
    .customer-pending-order {
        border-radius: 0px 0px 10px 10px;
        background: linear-gradient(180deg, #f9fdff 0.1%, #f9fdff 100%);
        margin-bottom: 5px;
        color: #727272;
        text-decoration: none;
        display: block;

        .customer-pending-order-title {
            color: #102e4a;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            background: #eef7ff;
            width: 100%;
            display: block;
            padding: 8px;
            border-radius: 10px 10px 0px 0px;
        }

        .customer-pending-order-table {
            display: flex;
            justify-content: space-between;
            padding: 8px;

            div {
                display: flex;
                flex-direction: column;
                color: #4c4c4c;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            span {
                color: #727272;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    .customer-info {
        position: relative;
        background: #eef7ff;
        border-radius: 10px;
        height: 80px;
        display: flex;
        align-items: center;
        padding: 12px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .customer-info-wrap {
            display: flex;
            flex-direction: column;

            .customer-info-name {
                color: #102e4a;
                font-size: 14px;
                font-weight: 600;
                display: block;
            }

            .customer-info-occasion {
                color: #102e4a;
                font-size: 12px;
            }

            .customer-info-action {
                border-radius: 10px;
                border: 1px solid #1e9ce8;
                background: #eef7ff;
                color: #1e9ce8;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                padding: 3px 6px;
                display: block;

                i {
                    padding-right: 5px;
                }
            }
        }

        .asset-parent-wrapper {
            margin-right: 10px;

            .asset {
                height: 50px;
                width: 50px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .customer-info-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ececec;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            color: #90a2b2;
            margin-right: 10px;
        }
    }

    .customer-info-close {
        position: absolute;
        right: 1em;
        height: 80px;
        padding-top: 10px;
    }
}

.dashboard-product-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .dashboard-product-card {
        margin-right: 5px;
        padding: 8px 13px;
        width: 180px;
        max-height: 250px;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0px 0px 8px 0px #cccccc;
        margin: 5px 10px;

        .card-quote {
            width: 137px;
            font-size: 6pt;
            display: flex;
            justify-content: center;
            text-align: center;
            overflow: auto;
            flex-direction: column;
            height: 70px;
        }

        .dashboard-product-card-label {
            font-size: 11px;
            height: 34px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            padding-top: 10px;
            line-height: 13px;
            font-size: 15px;
            width: 150px;
            overflow: hidden;
            white-space: nowrap;

            a {
                text-overflow: ellipsis;
                width: 160px;
                overflow: hidden;
            }
        }

        .attributes-label {
            font-size: 13px;
            color: #878787;
        }
    }

    .asset-parent-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 5px;

        img {
            vertical-align: middle;
            border-style: none;
            width: 100%;
            object-fit: cover;
            height: 120px;
            border-radius: 6px;
        }
    }
}

.dashboard-appointments-card {
    .appointments-calls {
        a {
            text-decoration: none;
        }

        .call {
            overflow: hidden;
        }

        .cancelled-appointment {
            .cancel-line {
                width: calc(100% - 62px);
                height: 2px;
                background: red;
                position: absolute;
                margin-top: 13px;
            }

            a {
                text-decoration: none;
                color: #ccc;

                &:hover {
                    background: initial;
                    color: #ccc !important;
                    cursor: auto;

                    i {
                        color: #ccc;
                    }
                }

                i {
                    color: #ccc;
                }
            }
        }
    }

    .nav-tabs {
        border-radius: 10px;
        overflow: hidden;

        .nav-item {
            background: #f4f4f4;
            width: 50%;
            display: flex;
            justify-content: center;
            border: none;
            overflow: hidden;

            .nav-link {
                color: #102e4a;
            }

            &:only-child {
                border-radius: 10px;
                width: 100%;
            }

            &:first-child {
                border-radius: 10px 0px 0px 10px;
            }

            &:last-child {
                border-radius: 0px 10px 10px 0px;
            }

            .active {
                background: #102e4a;
                color: white;
                width: 100%;
            }
        }
    }

    .recharts-responsive-container {
        background: #f9fdff;
        border-radius: 10px;

        &:first-child {
            margin-right: 10px;
        }
    }

    .inStoreStats {
        .recharts-text.recharts-cartesian-axis-tick-value {
            fill: #4fb500;
            font-weight: 600;
            font-size: 12px;
        }
    }

    .inStoreStatsCancelled {
        .recharts-text.recharts-cartesian-axis-tick-value {
            fill: #db3056;
            font-weight: 600;
            font-size: 12px;
        }
    }

    .virtualStats {
        .recharts-text.recharts-cartesian-axis-tick-value {
            fill: #4fb500;
            font-weight: 600;
            font-size: 12px;
        }
    }

    .virtualStatsCancelled {
        .recharts-text.recharts-cartesian-axis-tick-value {
            fill: #db3056;
            font-weight: 600;
            font-size: 12px;
        }
    }

    .rating-card {
        display: flex;
        justify-content: center;
        border: 2px dashed #102e4a;
        border-radius: 50%;
        height: 135px;
        width: 135px;
        align-items: center;
        margin: 0 auto;

        .rating-value {
            background: #1e9ce8;
            height: 125px;
            width: 125px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #102e4a;
            flex-direction: column;
            text-align: center;
        }

        .number {
            font-size: 26px;
            font-weight: 600;
            display: block;
        }

        .stars {
            font-size: 14px;
            display: block;
        }
    }

    .rating-response-wrap {
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .rating-communication-value,
    .rating-response-value,
    .rating-overall-value {
        background: #f8f8f8;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        margin: 10px 0;
        padding: 10px;

        .rating-communication-title {
            display: flex;
            justify-content: space-between;
            padding: 0 5px;
            font-size: 14px;
            font-weight: 600;
            color: #102e4a;
            margin-bottom: 3px;
        }

        .rating-communication-foreground {
            background: #1e9ce8;
            width: 70%;
            height: 10px;
            display: block;
            position: absolute;
            border-radius: 4px;
        }

        .rating-response-foreground {
            background: #f40e6f;
            width: 90%;
            height: 10px;
            display: block;
            position: absolute;
            border-radius: 4px;
        }

        .rating-overall-foreground {
            background: #0e3355;
            width: 80%;
            height: 10px;
            display: block;
            position: absolute;
            border-radius: 4px;
        }

        .rating-communication-background {
            background: #e2e2e2;
            width: 95%;
            height: 10px;
            display: block;
            border-radius: 4px;
        }
    }

    .ranking-user-list {
        .ranking-user {
            background: #f8f8f8;
            border-radius: 10px;
            height: 80px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .ranking-number {
                border-radius: 10px 0px 0px 10px;
                background: #deefff;
                height: 80px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: #102e4a;
            }

            &.self-user {
                background: #eef7ff !important;

                .ranking-number {
                    background: #1e9ce8;
                    color: #fff;
                }
            }
        }

        .ranking-user-wrap {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 50px - 20px);
            align-items: center;
            margin: 0 10px;

            .ranking-user-name {
                color: #102e4a;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                text-align: left;
                width: 100%;
                padding: 0 15px;
            }

            .ranking-user-image {
                width: 50px;

                .ranking-profile-picture {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                i {
                    font-size: 50px;
                }
            }

            .ranking-number {
                border-radius: 10px 0px 0px 10px;
                background: #deefff;
                height: 80px;
                display: inline-flex;
                width: 40px;
                position: absolute;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: #102e4a;
            }

            .ranking-user-rating {
                background: #d9e3ed;
                border-radius: 10px;
                padding: 10px;
                font-weight: 600;
                min-width: 95px;

                i {
                    padding-right: 10px;
                    color: #f5b333;
                }
            }
        }
    }

    .group-charts {
        display: flex;
    }

    .personal-stats {
        color: var(--parla-primary);
        font-weight: 600;
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .personal-colour {
        background: var(--parla-primary);
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 10px;
        border-radius: 2px;
    }

    .global-stats {
        color: var(--parla-secondary);
        font-weight: 600;
        margin-left: 10px;
        display: flex;
        align-items: center;
    }

    .global-colour {
        background: var(--parla-secondary);
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-right: 10px;
        border-radius: 2px;
    }

    .cancels-stats {
        color: #f44336;
    }

    .small-index {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.plan-counter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5em;

    .plan-counter-item {
        display: flex;
        width: 45%;
        justify-content: unset;
        align-items: center;
        height: 50px;

        i {
            padding-right: 5px;
            font-size: 22px;
            padding-bottom: 6px;
        }

        .usage {
            display: flex;
            align-items: center;

            .usage-title {
                white-space: nowrap;
                padding-right: 10px;
                margin-bottom: 0;
            }

            .usage-value {
                font-weight: 600;
                font-size: 20px;
                color: #3ea73e;
            }
        }
    }
}

.total-sales-label {
    padding-right: 15px;
}

.account-page {
    padding: 0;

    .dashboard-header {
        padding: 0;
    }
}

.integrations-page {
    .dashboard-header {
        padding: 0;
    }
}

.pro-tips {
    font-size: 18px;
    padding: 30px;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .pro-tips-title {
        display: flex;
        align-items: center;
        padding-bottom: 20px;

        span {
            color: #102e4a;
            font-size: 30px;
            font-weight: 600;
        }

        i {
            font-size: 60px;
            color: #f2f9ff;
            padding-right: 20px;
        }
    }

    .pro-tips-body {
        font-weight: 600;
        color: #102e4a;
        text-align: justify;
    }

    .pro-tips-buttons {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        width: 100%;
        font-size: 10px;

        a {
            padding-right: 5px;
            text-decoration: none;
            color: #878787;

            &.active {
                color: #102e4a;
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .integrations-page {
        .dashboard-header {
            padding: 0 15px;
        }

        .tenants-container {
            .cards-wrapper {
                .card {
                    margin: 5px 0;
                }
            }
        }
    }
}

.dashboard-fishfinger {
    display: flex;
    flex-direction: row;

    .card {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #e4e8f1;
        border-radius: 0.25rem;
    }
}

.dashboard-header {
    display: flex;

    .card {
        width: 100%;
        border: 1px solid #e4e8f1;
        border-radius: 0.25rem;
    }

    .card:hover {
        color: #2e2e2e;
    }

    .card-header {
        margin-bottom: 20px;
        padding: 15px;
        background: #fff;

        .card-subtitle {
            color: #9bc4fc;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.4px;
        }
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li:last-child {
        margin: 0px;
    }

    li {
        text-overflow: ellipsis;
        width: 100%;
        height: 100px;
        font-size: 14px;
        padding: 10px;
        list-style: none;
        font-weight: 600;
        color: #afafaf;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        margin: 0 10px 0 0;
        overflow: hidden;

        .bottom-text {
            font-size: 11px;
        }

        .icon {
            line-height: 40px;
            color: #fff;
            padding-right: 25px;
            align-self: center;
            height: 70px;
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            margin-right: 15px;

            i {
                padding-left: 30px;
                font-size: 32px;
            }
        }
    }

    .main-text {
        font-size: 18px;
        padding: 6px 0 0;
        color: #2e2e2e;
        cursor: pointer;
    }
}

.dashboard-appointments-card {
    .appointments-calls {
        .call {
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            color: #333;
            padding: 2px 5px;

            .user-appointment-name {
                i {
                    padding-right: 5px;
                    color: #ccc;
                }
            }

            &:hover {
                background: #9bc4fc;
                color: #fff;
                cursor: pointer;

                i {
                    color: #fff;
                }
            }
        }

        .sep-inline {
            padding: 0 10px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .dashboard-header {
        flex-direction: column;
        padding: 0;

        li {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .tenants-container {
        .cards-wrapper {
            flex-direction: column;
            width: 100%;
            padding: 0;

            .card {
                width: 100%;
                margin: 25px 0;
                overflow: hidden;
            }

            .card-holder {
                width: 100%;
            }

            .col-6 {
                max-width: max-content;
                width: 100%;
                display: contents;

                .card {
                    width: 100%;
                }
            }
        }
    }

    .dashboard-product-card-wrapper {
        .dashboard-product-card {
            padding: 8px 13px;
            width: 140px;
            height: 230px;
            border-radius: 8px;
            cursor: pointer;
            box-shadow: 0px 0px 8px 0px #cccccc;
            margin: 5px 0;
            overflow: hidden;

            .dashboard-product-card-label a {
                width: 125px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .dashboard-header {
        display: none;
    }
}

@media screen and (max-width: 760px) {
    body {
        .container {
            margin-top: 10px;
        }

        .help-panel {
            min-width: 100%;
        }

        .help-icon {
            right: 8px;
            top: 40px;
            z-index: 1;
        }

        .dashboard-container {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            overflow-x: hidden;
            box-sizing: border-box;

            .dashboard-block {
                width: 100%;
                margin: 0 0 15px 0;
            }

            .dashboard-header-subtitle {
                margin-bottom: 5px;
            }

            .dashboard-card-sm-title {
                flex-direction: column;
                align-items: flex-start;
            }

            .departments-availability-table {
                padding-bottom: 20px;

                .department-availability {
                    padding: 5px 0 5px 35px;
                }
            }

            .col-2-dept {
                width: 45%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .col-3-chat {
                width: 80px;
            }

            .col-12 {
                &.row {
                    --bs-gutter-x: 0;
                    flex-direction: column-reverse;
                }
            }

            .dashboard-sales-card-info-wrap,
            .shortcut-card-wrap {
                flex-direction: column;

                .dashboard-sales-card-info {
                    margin: 5px 0px 15px 0;
                }

                .shortcut-card {
                    width: 100%;
                    margin: 5px 0;
                }
            }

            .department-availability-wrap {
                height: 100% !important;
            }
        }
    }

    .tenants-container {
        .cards-wrapper {
            .card {
                margin: 5px 0;
            }
        }
    }
}
.swiper-navigation-hide {
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination-bullet {
        display: none !important;
    }
}

.slideShow {
    width: 100%;
    max-width: 650px;
    display: flex;
    left: 50%;
    overflow: hidden;

    img,
    video,
    iframe {
        width: 600px;
        margin-right: 5px;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        #myVideo {
            // position: relative !important;
            right: 0;
            bottom: 0;
            min-width: 100%;
            height: auto;
            max-height: 100%;
        }

        .slide-overflow {
            overflow: hidden;
            object-fit: contain;
        }
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        min-height: 300px;
        min-width: 400px;
        height: auto;
        overflow: hidden;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 10px;
        right: auto;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: calc(var(--swiper-navigation-size) / 44 * 27);
        height: var(--swiper-navigation-size);
        margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000 !important;
        fill: black !important;
        stroke: black !important;
        text-shadow:
            2px 0px 3px #ffffff,
            -2px 0px 3px #ffffff;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 10px;
        left: 0;
        width: 100%;
    }

    .swiper-pagination {
        position: absolute;
        text-align: center;
        transition: 0.3s opacity;
        transform: translate3d(0, 0, 0);
        z-index: 10;
    }

    .swiper-pagination-bullet-active {
        background-color: #000;
    }
}

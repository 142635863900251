#react-paginate {
    clear: both;

    ul {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
    }

    li {
        display: inline-block;
        padding-left: 15px;
        padding-right: 15px;
    }

    .item {
        align-items: center;
        color: #444444;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 40px;
        justify-content: center;
        width: 40px;
    }

    .disabled-page {
        color: #808e9b;
    }

    .active {
        border: solid 1px #808e9b;
        border-radius: 40px;
        color: #808e9b;
        height: 23px;
        outline: none;
    }

    .pagination {
        display: flex;
        flex-direction: row;
        height: 10px;
        justify-content: center;
        list-style-type: none;
        position: relative;
    }

    .pagination-page {
        font-weight: 700;
    }

}
	
#react-paginate + .insta-btns-wrap {
    display: none;
  }

.insta-btns-wrap {
    display: flex;
    justify-content: center;
    clear: both;

    .insta-btns {
        background: none;
        border: solid 1px #808e9b;
        border-radius: 40px;
        color: #808e9b;
        margin: 0 10px 15px;
        padding: 0px 20px;
        font-size: 15px;
    }
}
.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .data {
        width: 70%;
        .item {
            margin-bottom: 15px;
        label{
            margin-right: 10px;
        }
        .message {
            font-size: large;
            font-weight: bold;
        }

        &.stack {
            white-space: pre-line;
            height: 200px;
            overflow: scroll;
        }
    }
    }
}